// Amazon Cognito creates a session which includes the id, access, and refresh tokens of an authenticated user.
import { AuthenticationDetails, CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';

const AWS = require('aws-sdk');

const msPerMinute = 60000;
const tokenValidMinutes = 60;
const tokenValidThrough = {};

/**
 * Authenticates a user using AWS Cognito.
 *
 * @async
 * @param {*} cognitoUser
 * @param {*} cognitoAuthenticationDetails
 * @returns {Promise}
 */
function asyncAuthenticateUser(cognitoUser, cognitoAuthenticationDetails) {
    return new Promise((resolve, reject) => {
        cognitoUser.authenticateUser(cognitoAuthenticationDetails, {
            'onSuccess': resolve,
            'onFailure': reject,
            'newPasswordRequired': resolve,
        });
    });
}

/**
 * Fetches JWT data from Cognito.
 *
 * @async
 * @returns {Object} Cognito response object containing authentication data for a user.
 */
async function getCognitoTokens() {
    const authenticationData = {
        'Username': process.env.VUE_APP_PRODUCT_FEED_COGNITO_USERNAME,
        'Password': process.env.VUE_APP_PRODUCT_FEED_COGNITO_PASSWORD,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const poolData = {
        'UserPoolId': process.env.VUE_APP_PRODUCT_FEED_COGNITO_USER_POOL_ID,
        // app client id without secret
        'ClientId': process.env.VUE_APP_PRODUCT_FEED_COGNITO_CLIENT_ID,
    };
    const userPool = new CognitoUserPool(poolData);

    const userData = {
        'Username': process.env.VUE_APP_PRODUCT_FEED_COGNITO_USERNAME,
        'Pool': userPool,
    };
    const cognitoUser = new CognitoUser(userData);

    const result = await asyncAuthenticateUser(cognitoUser, authenticationDetails);

    return result;
}

/**
 * Fetches the token required to authenticate requests to the Products GraphQL API or Kinesis analytics.
 *
 * @returns {String} authToken
 */
async function getAuthToken(tokenName) {
    const currentTime = new Date();
    let authToken = localStorage.getItem(tokenName);

    if (authToken && tokenValidThrough[tokenName] > currentTime) {
        return authToken;
    }

    const tokenFetchTime = new Date();
    tokenValidThrough[tokenName] = new Date(tokenFetchTime.getTime() + (msPerMinute * tokenValidMinutes));

    console.log(`Fetching ${tokenName} from Cognito.`);
    const tokenData = await getCognitoTokens();

    switch (tokenName) {
        // Apollo requires access token to authenticate requests to Products API GraphQL.
        case 'apollo-token':
            authToken = tokenData.getAccessToken().getJwtToken();
            break;
        // Kinesis requires id token to authenticate requests to AWS resources using IAM profiles.
        case 'kinesis-token':
            authToken = tokenData.getIdToken().getJwtToken();
            break;
        default:
            break;
    }

    localStorage.setItem(tokenName, authToken);

    return authToken;
}

/**
 * Get AWS credentials that allow Product Feed to write to Kinesis.
 */
async function getAwsCognitoIdentityCredentials() {
    const token = await getAuthToken('kinesis-token');

    return new AWS.CognitoIdentityCredentials({
        'clientConfig': { 'region': 'us-east-1' },
        'IdentityPoolId': 'us-east-1:4355bec0-4707-46dc-982e-6228a57880c1',
        'Logins': { 'cognito-idp.us-east-1.amazonaws.com/us-east-1_Krgx79qSg': token },
    });
}

const _getCognitoTokens = getCognitoTokens;
export { _getCognitoTokens as getCognitoTokens };
const _getAuthToken = getAuthToken;
export { _getAuthToken as getAuthToken };
const _getAwsCognitoIdentityCredentials = getAwsCognitoIdentityCredentials;
export { _getAwsCognitoIdentityCredentials as getAwsCognitoIdentityCredentials };
