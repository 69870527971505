<template>
    <div
        id="product-feed"
        class="columns box"
    >
        <!-- Icons for Buefy styling -->
        <link
            rel="stylesheet"
            href="https://cdn.jsdelivr.net/npm/@mdi/font@5.8.55/css/materialdesignicons.min.css"
        >
        <div
            id="sidebar-area"
            class="column is-one-fifth box scrollable"
        >
            <Sidebar />
        </div>
        <!-- column will take 4/5 of space by using "auto" default after Sidebar column uses "is-one-fifth" -->
        <div
            id="product-tile-container-area"
            class="column box scrollable"
        >
            <ProductTileContainer />
        </div>
    </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import ProductTileContainer from './ProductTileContainer.vue';
import Sidebar from './Sidebar.vue';
import countryOptions from '../assets/countries.json';

export default {
    'name': 'ProductFeed',
    'components': {
        ProductTileContainer,
        Sidebar,
    },
    'props': {
        'searchDefaults': {
            'type': Object,
            'required': false,
            'default': function setDefaults() {
                return {
                    'imageUrl': null,
                    'objectDetectionInput': null,
                    'searchTerm': null,
                    'colorId': null,
                };
            },
        },
        'parentApplication': {
            'type': String,
            'required': true,
        },
        'clientId': {
            'type': String,
            'required': true,
        },
        'triggerSearchOnOpen': {
            'type': Boolean,
            'required': false,
            'default': false,
        },
        'parentApplicationSessionId': {
            'type': String,
            'required': false,
            'default': null,
        },
    },
    'computed': {
        ...mapFields('productFeedStore', [
            'sidebarImageUrl',
            'objectDetectionInput',
            'sidebarSearchTerms',
            'colorId',
            'country',
        ]),
    },
    async created() {
        this.$store.commit('productFeedStore/setOpenTime');
        this.$store.commit('productFeedStore/setSessionId');
        this.$store.commit('productFeedStore/setClientId', this.clientId);
        this.$store.commit('productFeedStore/setParentApplication', this.parentApplication);
        this.$store.commit('productFeedStore/setParentApplicationSessionId', this.parentApplicationSessionId);

        const country = Object.keys(countryOptions).find((key) => {
            return countryOptions[key].code === this.searchDefaults.countryCode;
        });

        // NOTE: initial store values are set here on created since rendering ProductFeed with v-if will recreate.
        console.log('[ProductFeed.vue created] searchDefaults:', this.searchDefaults);
        this.$watch('searchDefaults', () => {
            this.sidebarImageUrl = this.searchDefaults.imageUrl;
            this.objectDetectionInput = this.searchDefaults.objectDetectionInput;
            this.sidebarSearchTerms = this.searchDefaults.searchTerm;
            this.colorId = this.searchDefaults.colorId;
            this.country = country;
        }, { 'immediate': true });

        await this.$store.dispatch('productFeedStore/setIpAddress');

        if (this.triggerSearchOnOpen) {
            this.$store.dispatch('productFeedStore/runSearchQuery', { 'isMoreLikeThisQuery': false });
        }
    },
};
</script>

<style scoped lang="scss">
#sidebar-area {
    // NOTE: this was added so the sidebar scrolling works properly with the navbar overlay from Admin4.
    height: 100%;
}
.scrollable {
    height: 100vh;
    overflow-y: scroll;
}
#product-tile-container-area {
    // NOTE: position:relative is here so the loading animation in ProductTileContainer won't take up the whole screen
    position: relative;
    height: auto;
}
</style>
