<template>
    <div id="app">
        <div
            id="product-feed-area"
        >
            <ProductFeed
                :search-defaults="productFeedDefaults"
                :parent-application="productFeedParentApplication"
                :client-id="productFeedClientId"
            />
        </div>
    </div>
</template>

<script>
import ProductFeed from './components/ProductFeed.vue';

export default {
    'name': 'App',
    'components': {
        ProductFeed,
    },
    data() {
        return {
            'productFeedDefaults': {
                'imageUrl': null,
                'searchTerm': null,
                'colorId': null,
                'category': null,
            },
            'productFeedClientId': '',
            'productFeedParentApplication': window.location.hostname,
        };
    },
    async mounted() {
        console.log('[App.vue mounted] productFeedDefaults:', this.productFeedDefaults);
        this.$root.$on('productFeedEmitData', (productData) => {
            console.log('[productFeedEmitData listener] postMessage to parent window:', JSON.stringify(productData));
            window.parent.postMessage(JSON.stringify(productData), '*');
        });
        const queryString = window.location.search;
        console.log('[ProductFeed] queryString:', queryString);
        const queryStringParams = new URLSearchParams(queryString);

        if (queryStringParams.has('parentApplication')) this.productFeedParentApplication = queryStringParams.get('parentApplication');

        if (queryStringParams.has('clientId')) this.productFeedClientId = queryStringParams.get('clientId');

        if (queryStringParams.has('imageUrl')) this.productFeedDefaults.imageUrl = queryStringParams.get('imageUrl');

        if (queryStringParams.has('searchTerm')) this.productFeedDefaults.searchTerm = queryStringParams.get('searchTerm');

        if (queryStringParams.has('colorId')) this.productFeedDefaults.colorId = queryStringParams.get('colorId');

        this.productFeedDefaults = { ...this.productFeedDefaults };
    },
};
</script>

<style scoped lang="scss">
</style>
