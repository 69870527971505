<template>
    <div id="sidebar">
        <div id="sidebar-image-area">
            <img
                :src="sidebarImageUrl"
                alt="No Image URL Given"
            >
        </div>
        <b-button
            id="search-button"
            type="is-primary"
            size="is-large"
            expanded
            @click="searchClicked"
        >
            Search
        </b-button>
        <div id="sidebar-input-area">
            <!-- Image URL Input -->
            <b-field label="Image URL">
                <b-input
                    id="visual-search-url-input"
                    v-model="sidebarImageUrl"
                    @focus="$event.target.select()"
                />
            </b-field>
            <!-- Text Search Input -->
            <b-field label="Search Terms">
                <b-input
                    id="text-search-input"
                    v-model="sidebarSearchTerms"
                    @focus="$event.target.select()"
                />
            </b-field>
            <!-- Gender selection -->
            <section>
                <b-field label="Gender">
                    <b-checkbox-button
                        v-model="sidebarGender"
                        native-value="male"
                    >
                        <span>Male</span>
                    </b-checkbox-button>
                    <b-checkbox-button
                        v-model="sidebarGender"
                        native-value="unisex"
                    >
                        <span>Unisex</span>
                    </b-checkbox-button>
                    <b-checkbox-button
                        v-model="sidebarGender"
                        native-value="female"
                    >
                        <span>Female</span>
                    </b-checkbox-button>
                </b-field>
            </section>
            <!-- Price Range Inputs -->
            <b-field label="Price Range">
                <b-input
                    id="price-floor"
                    v-model="minPrice"
                    placeholder="Min"
                    :lazy="true"
                    @focus="$event.target.select()"
                />
                <b-input
                    id="price-ceiling"
                    v-model="maxPrice"
                    placeholder="Max"
                    :lazy="true"
                    @focus="$event.target.select()"
                />
            </b-field>
            <b-field label="Category">
                <b-autocomplete
                    v-model="googleProductCategory"
                    :data="googleProductCategoryAutocomplete"
                    placeholder="Search Category"
                    field="name"
                    :clearable="true"
                    @typing="googleCategorySearchString = $event;"
                />
            </b-field>
            <!-- Allowed Retailer Tag Inputs -->
            <b-field label="Allowed Retailers">
                <b-taginput
                    v-model="allowedRetailerTags"
                    :data="retailersAutocomplete"
                    autocomplete
                    field="name"
                    placeholder="Search Retailer"
                    @typing="retailerSearchString = $event;"
                />
            </b-field>
            <!-- Banned Retailer Tag Inputs -->
            <b-field label="Blocked Retailers">
                <b-taginput
                    v-model="blockedRetailerTags"
                    :data="retailersAutocomplete"
                    autocomplete
                    field="name"
                    placeholder="Search Retailer"
                    @typing="retailerSearchString = $event;"
                />
            </b-field>
            <!-- Allowed Brands Tag Inputs -->
            <b-field label="Allowed Brands">
                <b-taginput
                    v-model="allowedBrandTags"
                    :data="brandsAutocomplete"
                    autocomplete
                    field="name"
                    placeholder="Search Brand"
                    @typing="brandSearchString = $event;"
                />
            </b-field>
            <!-- Blocked Brands Tag Inputs -->
            <b-field label="Blocked Brands">
                <b-taginput
                    v-model="blockedBrandTags"
                    :data="brandsAutocomplete"
                    autocomplete
                    field="name"
                    placeholder="Search Brand"
                    @typing="brandSearchString = $event;"
                />
            </b-field>
            <!-- Color dropdown -->
            <b-field label="Color">
                <b-select
                    v-model="colorId"
                    placeholder="Select a color"
                >
                    <option
                        :key="-1"
                        :value="null"
                    >
                        Clear Filter
                    </option>
                    <option
                        v-for="(color, id, index) in colorOptions"
                        :key="index"
                        :value="id"
                    >
                        {{ color }}
                    </option>
                </b-select>
            </b-field>
            <!-- Country Autocomplete Select -->
            <b-field label="Country">
                <b-autocomplete
                    v-model="country"
                    :data="filteredCountries"
                    field="name"
                    placeholder="Select a country"
                    icon="magnify"
                    clearable
                    @typing="searchCountries"
                >
                    <template #empty>
                        No results found
                    </template>
                </b-autocomplete>
            </b-field>
        </div>
    </div>
</template>

<script>
import gql from 'graphql-tag';
import { mapFields, createHelpers } from 'vuex-map-fields';
import colorCodes from '../assets/colorCodes.json';
import countryOptions from '../assets/countries.json';

const { 'mapFields': mapCheckboxFields } = createHelpers({
    'getterType': 'getField',
    'mutationType': 'updateCheckboxField',
});

export default {
    'name': 'Sidebar',
    'apollo': {
        'googleProductCategoryAutocomplete': {
            'query': gql` query($googleCategoryString: String) {
                googleProductCategoryAutocomplete(googleProductCategoryString: $googleCategoryString) {
                    name
                }
            }`,
            variables() {
                return {
                    'googleCategoryString': this.googleCategorySearchString,
                };
            },
            skip() {
                return !this.googleCategorySearchString.trim();
            },
        },
        'brandsAutocomplete': {
            'query': gql` query($brandName: String) {
                brandsAutocomplete(brandName: $brandName) {
                    id
                    name
                }
            }`,
            variables() {
                return {
                    'brandName': this.brandSearchString,
                };
            },
            skip() {
                return !this.brandSearchString.trim();
            },
        },
        'retailersAutocomplete': {
            'query': gql` query($retailerName: String) {
                retailersAutocomplete(retailerName: $retailerName) {
                    id
                    name
                }
            }`,
            variables() {
                return {
                    'retailerName': this.retailerSearchString,
                };
            },
            skip() {
                return !this.retailerSearchString.trim();
            },
        },
    },
    data() {
        return {
            'retailerSearchString': '',
            'brandSearchString': '',
            'googleCategorySearchString': '',
            'searchTerm': null,
            'colorOptions': colorCodes,
            'filteredCountries': [],
            'filterPayload': {},
        };
    },
    'computed': {
        ...mapFields('productFeedStore', [
            'sidebarImageUrl',
            'sidebarSearchTerms',
            'colorId',
            'country',
            'googleProductCategory',
            'allowedRetailerTags',
            'blockedRetailerTags',
            'allowedBrandTags',
            'blockedBrandTags',
            'minPrice',
            'maxPrice',
        ]),
        ...mapCheckboxFields('productFeedStore', [
            'sidebarGender',
        ]),
    },
    'methods': {
        /**
         * Click listener for the "Search" button that handles formatting and setting the text search filters before
         * emitting to the parent component.
         */
        searchClicked() {
            this.$store.dispatch('productFeedStore/runSearchQuery', { 'isMoreLikeThisQuery': false });
        },
        /**
         * Populates autocomplete dropdown for the country filter as user is typing.
         * @param {String} text the user-defined string entered into the "Country" search bar.
         */
        searchCountries(text) {
            this.filteredCountries = [];

            Object.keys(countryOptions).forEach((key) => {
                if (key.toLowerCase().includes(text.toLowerCase())) {
                    this.filteredCountries.push({
                        'name': key,
                        'code': countryOptions[key].code,
                    });
                }
            });
        },
    },
};
</script>

<style lang="scss">
// If CSS is scoped, it will not be able to pass this imperative to the child Buefy components
// This ensures that autocomplete dropdown options wrap text so they can be seen
#sidebar-input-area {
    a.dropdown-item {
        white-space: break-spaces !important;
    }
}
#search-button {
    margin-top: 1em;
    margin-bottom: 1em;
}
</style>
