/* eslint-disable import/prefer-default-export */
/**
 * AWS SDK helper module.
 * @module aws/base
 */

const AWS = require('aws-sdk');
const bluebird = require('bluebird');

AWS.config.region = 'us-east-1';
AWS.config.setPromisesDependency(bluebird);

function setAwsCredentials(credentials) {
    AWS.config.credentials = credentials;
}

const _setAwsCredentials = setAwsCredentials;
export { _setAwsCredentials as setAwsCredentials };
