<template>
    <div id="product-tile-container">
        <b-loading
            :active="$apollo.loading"
            :is-full-page="false"
        />
        <div
            v-show="!$apollo.loading"
            class="columns is-multiline"
        >
            <ProductTile
                v-for="(searchResult, index) in productKnnSearch"
                :key="index"
                :tile-rank="index"
                :product-data="searchResult.product"
                :search-score="searchResult.searchScore"
            />
        </div>
    </div>
</template>

<script>

import gql from 'graphql-tag';
import ProductTile from './ProductTile.vue';

export default {
    'name': 'ProductTileContainer',
    'components': {
        ProductTile,
    },
    'methods': {
        trackSearchRequestMetric(partial, queryName) {
            let searchType;

            switch (queryName) {
                case 'productKnnSearch':
                    searchType = 'filtered_knn_search';
                    break;
                default:
                    searchType = 'filtered_knn_search';
                    break;
            }

            if (typeof partial === 'undefined') {
                console.log(`[${queryName}] GOT A NETWORK RESULT`);

                const currentTime = new Date().getTime();
                const params = {
                    'query_duration_ms': currentTime - this.$store.state.productFeedStore.lastQueryStartTime,
                    'ip_address': this.$store.state.productFeedStore.ipAddress,
                    'protobuf_fields': JSON.parse(this.$apollo.queries[queryName].previousVariablesJson),
                    'query_id': this.$store.state.productFeedStore.lastQueryId,
                    'search_type': searchType,
                    'parent_application': this.$store.state.productFeedStore.parentApplication,
                    'parent_application_session_id': this.$store.state.productFeedStore.parentApplicationSessionId,
                    'version': this.$store.state.productFeedStore.version,
                };

                const kinesisPayload = {
                    'timestamp': currentTime / 1000.0,
                    'application': this.$store.state.productFeedStore.application,
                    'event': 'search',
                    'params': params,
                    'session_id': this.$store.state.productFeedStore.sessionId,
                    'client_id': this.$store.state.productFeedStore.clientId,
                };
                this.$store.dispatch('productFeedStore/recordAnalyticsEvent', kinesisPayload);
            } else {
                console.log(`[${queryName}] GOT A CACHED RESULT`);
            }
        },
    },
    'apollo': {
        'productKnnSearch': {
            'query': gql` query($imageUrl: String
                                $categoryId: Int,
                                $objectDetectionInput: ObjectDetectionInput,
                                $textSearchTerms: String,
                                $gender: String,
                                $color: String,
                                $countryCode: String,
                                $allowedCategories: [String],
                                $allowedRetailerIds: [Int],
                                $blockedRetailerIds: [Int],
                                $allowedBrandIds: [Int],
                                $blockedBrandIds: [Int],
                                $minPrice: Float,
                                $maxPrice: Float,
                                $productId: Int) {
                filteredKnnSearchWithEmbedGen(imageUrl: $imageUrl,
                                               categoryId: $categoryId,
                                               objectDetectionInput: $objectDetectionInput,
                                               resultLimit: 40,
                                               query: $textSearchTerms,
                                               gender: $gender,
                                               color: $color,
                                               countryCode: $countryCode,
                                               googleProductCategoryAllowlist: $allowedCategories,
                                               retailerIdsAllowlist: $allowedRetailerIds,
                                               retailerIdsBlocklist: $blockedRetailerIds,
                                               brandIdsAllowlist: $allowedBrandIds,
                                               brandIdsBlocklist: $blockedBrandIds,
                                               priceRangeGte: $minPrice,
                                               priceRangeLte: $maxPrice,
                                               productId: $productId
                                               availability: [1, 2, 3, 4]) {
                    metricId,
                    results {
                        searchScore,
                        product {
                            id
                            productId
                            title
                            description
                            link
                            retailerName
                            retailerId
                            brand
                            brandId
                            imageLink
                            additionalImageLink
                            availability
                            price
                            currency
                            primaryCategory
                            secondaryCategory
                            tertiaryCategory
                            gtin
                            color
                            variationId
                            country
                            productLastUpdatedAt
                            updatedAt
                            gender
                            googleProductCategory
                        }
                    }
                }
            }`,
            'update': (data) => { return data.filteredKnnSearchWithEmbedGen[0].results; },
            variables() {
                return this.$store.state.productFeedStore.queryArgs;
            },
            skip() {
                return !this.$store.state.productFeedStore.queryArgs.textSearchTerms
                    && !this.$store.state.productFeedStore.queryArgs.imageUrl;
            },
            result({ partial }) {
                this.trackSearchRequestMetric(partial, 'productKnnSearch');
            },
        },
    },
};
</script>

<style scoped lang="scss">
</style>
