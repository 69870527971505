import Vue from 'vue';
import Vuex from 'vuex';

import Buefy from 'buefy';
import 'buefy/dist/buefy.css';

import VueApollo from 'vue-apollo';
import ApolloClient from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';

import productFeedStoreModule from './store/productFeedModule';

const Cognito = require('./aws/cognito');

const App = process.env.VUE_APP_IS_ADMIN_TEST ? require('./AdminTesting.vue').default : require('./App.vue').default;

Vue.use(Buefy);
Vue.use(VueApollo);
Vue.use(Vuex);

const store = new Vuex.Store();
store.registerModule('productFeedStore', productFeedStoreModule);
/**
 * Adds authorization token and session id to Apollo requests.
 *
 * @param {*} uri
 * @param {*} options
 * @returns {Promise} fetch promise that resolves to a Response object.
 */
const awsGraphqlFetch = async (uri, options) => {
    const token = await Cognito.getAuthToken('apollo-token');
    // eslint-disable-next-line no-param-reassign
    options.headers.Authorization = `Bearer ${token}`;
    options.headers['product-feed-session-id'] = store.state.productFeedStore.sessionId;

    return fetch(uri, options);
};

const apolloClient = new ApolloClient({
    'link': new HttpLink({
        'uri': process.env.VUE_APP_GRAPHQL_ENDPOINT,
        'fetch': awsGraphqlFetch,
    }),
    'cache': new InMemoryCache(),
});

const apolloProvider = new VueApollo({
    'defaultClient': apolloClient,
});

// eslint-disable-next-line no-new
new Vue({
    'el': '#app',
    store,
    apolloProvider,
    'render': (h) => { return h(App); },
});
