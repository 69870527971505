const AWS = require('aws-sdk');
const https = require('https');
const Cognito = require('./cognito');

const base = require('./base');

const BASE_10 = 10;
const KINESIS_CONFIGS = {
    'maxRetries': 5,
    'httpOptions': {
        'agent': new https.Agent({
            'rejectUnauthorized': true,
            'keepAlive': true,
        }),
    },
};
let KINESIS = new AWS.Kinesis(KINESIS_CONFIGS);

/**
 * Records a list of analytics to Kinesis.
 *
 * @param {*} events The events that should get pushed to kinesis.
 */
async function recordAnalytics(events) {
    let kinesisResponse;

    const kinesisBatch = {
        'Records': [],
        'StreamName': 'thetake-ai-analytics',
    };

    for (let x = 0; x < events.length; x++) {
        const event = events[x];

        kinesisBatch.Records.push({
            'Data': JSON.stringify(event),
            'PartitionKey': `${parseInt(Math.random() * 1000, BASE_10)}`,
        });
    }

    try {
        kinesisResponse = await KINESIS.putRecords(kinesisBatch).promise();
    } catch (e) {
        if (e.name === 'CredentialsError') {
            console.warn('[recordAnalytics]:', e);
            console.log('[recordAnalytics] Retrying with new AWS credentials.');
            const newCredentials = await Cognito.getAwsCognitoIdentityCredentials();
            base.setAwsCredentials(newCredentials);
            KINESIS = await new AWS.Kinesis(KINESIS_CONFIGS);
            kinesisResponse = await KINESIS.putRecords(kinesisBatch).promise();
        } else {
            console.error('[recordAnalytics]:', e);
        }
    }

    if (kinesisResponse.FailedRecordCount > 0) {
        console.log('[recordAnalytics] FAILED RECORDS');

        kinesisResponse.Records.forEach((record) => {
            if (record.ErrorCode) {
                console.log(record);
            }
        });
    }

    return kinesisResponse;
}
exports.recordAnalytics = recordAnalytics;
