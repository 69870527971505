<template>
    <div id="app">
        <b-button
            id="test-btn"
            class="is-fullwidth"
            @click="setTestFilters()"
        >
            SET TEST FILTERS
        </b-button>
        <b-button
            id="augment-btn"
            class="is-fullwidth"
            @click="augmentClick()"
        >
            AUGMENT TEST FILTERS
        </b-button>
        <b-button
            id="open-btn"
            class="is-fullwidth"
            @click="toggleProductFeed()"
        >
            {{ productFeedOpen ? 'CLOSE' : 'OPEN' }} PRODUCT FEED
        </b-button>
        <div
            v-if="productFeedOpen"
            id="product-feed-area"
        >
            <ProductFeed
                :search-defaults="testFilters"
                :parent-application="productFeedParentApplication"
                :client-id="productFeedClientId"
                :trigger-search-on-open="true"
                parent-application-session-id="local-testing"
            />
        </div>
    </div>
</template>

<script>
import ProductFeed from './components/ProductFeed.vue';

export default {
    'name': 'App',
    'components': {
        ProductFeed,
    },
    data() {
        return {
            'testFilters': {
                'imageUrl': null,
                'searchTerm': 'mens shirt',
                'color': null,
                'category': null,
            },
            'productFeedOpen': false,
            'productFeedClientId': 'brandon@thetake.com',
            'productFeedParentApplication': 'AdminTesting',
        };
    },
    async mounted() {
        console.log('[AdminTesting.vue mounted] testFilters:', this.testFilters);
        this.$root.$on('productFeedEmitData', (productData) => {
            console.log('[productFeedEmitData listener] postMessage to parent window:', JSON.stringify(productData));
            window.parent.postMessage(JSON.stringify(productData), '*');
        });
    },
    'methods': {
        toggleProductFeed() {
            this.productFeedOpen = !this.productFeedOpen;
        },
        setTestFilters() {
            this.testFilters = {
                'searchTerm': 'womens dress',
                'colorId': '-1',
                'imageUrl': 'https://images.barcodelookup.com/9910/99109206-1.jpg',
            };
            console.log('[AdminTesting.vue setTestFilters] setTestFilters:', this.testFilters);
        },
        augmentClick() {
            // this.testFilters.imageUrl = 'https://www.lulus.com/images/product/xlarge/3200150_607722.jpg?w=560&hdpi=1';
            // pink dress url
            this.testFilters.imageUrl = 'https://img.ssensemedia.com/images/b_white,c_lpad,g_south,h_1086,w_724/c_scale,h_480/f_auto,q_auto/221593F052002_1/kika-vargas-ssense-exclusive-pink-mathilde-dress.jpg';
            this.testFilters = { ...this.testFilters };
            console.log('[AdminTesting.vue augmentClick] testFilters:', this.testFilters);
            // https://cdn.shopify.com/s/files/1/0015/5638/1732/products/17949.jpg?v=1629099774
        },
    },
};
</script>

<style scoped lang="scss">
</style>
