/* eslint-disable no-shadow */
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { getField, updateField } from 'vuex-map-fields';
import Kinesis from '../aws/kinesis';

import countryOptions from '../assets/countries.json';
import colorOptions from '../assets/colorCodes.json';
import { version } from '../.release';

// eslint-disable-next-line arrow-body-style
const state = () => ({
    'ipAddress': null,
    'parentApplication': null,
    'parentApplicationSessionId': null,
    'sessionId': null,
    'clientId': null,
    'openTime': null,
    'application': 'Product Feed',
    'lastQueryStartTime': null,
    'lastQueryEndTime': null,
    'lastQueryId': null,
    'analyticsCount': 0,
    'version': version,
    'queryArgs': {
        'imageUrl': null,
        'objectDetectionInput': null,
        'textSearchTerms': null,
        'gender': null,
        'color': null,
        'countryCode': null,
        'allowedCategories': null,
        'allowedRetailerIds': null,
        'blockedRetailerIds': null,
        'allowedBrandIds': null,
        'blockedBrandIds': null,
        'minPrice': null,
        'maxPrice': null,
        'productId': null,
    },
    'sidebarImageUrl': null,
    'objectDetectionInput': null,
    'sidebarSearchTerms': null,
    'sidebarGender': [],
    'allowedRetailerTags': [],
    'blockedRetailerTags': [],
    'allowedBrandTags': [],
    'blockedBrandTags': [],
    'minPrice': 0,
    'maxPrice': Infinity,
    'country': null,
    'googleProductCategory': null,
    'colorId': null,
    'moreLikeThisProductId': null,
});

const mutations = {
    updateField,
    // Allows v-model to be bound to store on a group of buefy checkboxes and allow only one to be selected at a time.
    updateCheckboxField(state, field) {
        const valueList = field.value;
        field.value = [valueList[valueList.length - 1]];
        updateField(state, field);
    },
    incrementAnalyticsCount(state) {
        state.analyticsCount++;
    },
    setIpAddress(state, ipAddress) {
        state.ipAddress = ipAddress;
    },
    setParentApplication(state, parentApplication) {
        state.parentApplication = parentApplication;
    },
    setParentApplicationSessionId(state, parentApplicationSessionId) {
        state.parentApplicationSessionId = parentApplicationSessionId;
    },
    setSessionId(state) {
        state.sessionId = uuidv4();
    },
    setClientId(state, clientId) {
        state.clientId = clientId;
    },
    setOpenTime(state) {
        state.openTime = new Date().getTime();
    },
    setLastQueryStartTime(state) {
        state.lastQueryStartTime = new Date().getTime();
    },
    setLastQueryEndTime(state) {
        state.lastQueryEndTime = new Date().getTime();
    },
    setLastQueryId(state) {
        state.lastQueryId = uuidv4();
    },
    setQueryArgs(state, isMoreLikeThisQuery) {
        // set allowed retailer ids
        const allowedRetailerIds = [];
        state.allowedRetailerTags.forEach((tag) => { return allowedRetailerIds.push(tag.id); });
        // set blocked retailer ids
        const blockedRetailerIds = [];
        state.blockedRetailerTags.forEach((tag) => { return blockedRetailerIds.push(tag.id); });
        // set allowed brand ids
        const allowedBrandIds = [];
        state.allowedBrandTags.forEach((tag) => { return allowedBrandIds.push(tag.id); });
        // set blocked brand ids
        const blockedBrandIds = [];
        state.blockedBrandTags.forEach((tag) => { return blockedBrandIds.push(tag.id); });

        state.queryArgs = {
            'imageUrl': isMoreLikeThisQuery ? null : state.sidebarImageUrl,
            'objectDetectionInput': state.objectDetectionInput,
            'textSearchTerms': state.sidebarSearchTerms,
            'gender': state.sidebarGender[0],
            'color': colorOptions[state.colorId],
            'countryCode': countryOptions[state.country] ? countryOptions[state.country].code : null,
            'allowedCategories': [state.googleProductCategory || ''],
            'allowedRetailerIds': allowedRetailerIds,
            'blockedRetailerIds': blockedRetailerIds,
            'allowedBrandIds': allowedBrandIds,
            'blockedBrandIds': blockedBrandIds,
            'minPrice': parseFloat(state.minPrice),
            'maxPrice': parseFloat(state.maxPrice),
            'productId': isMoreLikeThisQuery ? state.moreLikeThisProductId : null,
        };
    },
};

const actions = {
    async recordAnalyticsEvent({ commit }, event) {
        console.log('[recordAnalyticsEvent]: event', event);
        const kinesisResponse = await Kinesis.recordAnalytics([event]);
        console.log('[recordAnalyticsEvent] Kinesis response:', kinesisResponse);
        commit('incrementAnalyticsCount');
    },
    async setIpAddress({ commit }) {
        const responseData = (await axios.get('https://www.cloudflare.com/cdn-cgi/trace')).data;
        const responseDataMap = responseData.trim().split('\n').reduce((map, kvString) => {
            const kvPair = kvString.split('=');
            const key = kvPair[0];
            const value = kvPair[1];
            map[key] = value;

            return map;
        }, {});

        commit('setIpAddress', responseDataMap.ip);
    },
    runSearchQuery({ commit }, { isMoreLikeThisQuery }) {
        commit('setLastQueryId');
        commit('setLastQueryStartTime');
        commit('setQueryArgs', isMoreLikeThisQuery);
    },
};

const getters = {
    getField,
};

export default {
    'namespaced': true,
    'state': state,
    'mutations': mutations,
    'actions': actions,
    'getters': getters,
};
