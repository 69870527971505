<template>
    <div class="column box is-one-quarter product-tile">
        <img
            id="product-img"
            :src="productData.additionalImageLink[0]"
            @click="productImageClicked()"
        >
        <div id="brand">
            {{ productData.brand }}
        </div>
        <div id="price-and-retailer">
            {{ formattedPrice }} at {{ productData.retailerName }}
        </div>

        <div id="product-tile-buttons">
            <div id="more-like-this-btn-area">
                <b-tooltip
                    id="more-like-this-tooltip"
                    label="More like this"
                    position="is-bottom"
                    class="is-success"
                >
                    <b-button
                        id="more-like-this-button"
                        type="is-success"
                        icon-left="thumb-up"
                        expanded
                        @click="moreLikeThisClicked()"
                    />
                </b-tooltip>
            </div>
            <div id="add-product-btn-area">
                <b-button
                    id="add-product-button"
                    expanded
                    @click="addProductClicked()"
                >
                    Add Product
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';

export default {
    'name': 'ProductTile',
    'props': {
        'productData': {
            'type': Object,
            'required': true,
        },
        'tileRank': {
            'type': Number,
            'required': true,
        },
        'searchScore': {
            'type': Number,
            'required': true,
        },
    },
    'computed': {
        ...mapFields('productFeedStore', [
            'sidebarImageUrl',
            'objectDetectionInput',
            'moreLikeThisProductId',
        ]),
        formattedPrice() {
            const formatter = new Intl.NumberFormat('en-US', {
                'style': 'currency',
                'currency': this.productData.currency,
            });

            return formatter.format(this.productData.price);
        },
    },
    'methods': {
        async addProductClicked() {
            console.log('[addProductClicked]:', JSON.stringify(this.productData));
            // eslint-disable-next-line vue/no-mutating-props
            this.productData.price = Math.round((this.productData.price + Number.EPSILON) * 100) / 100;
            await this.trackMetric('add_product');
            this.$root.$emit('productFeedEmitData', this.productData);
        },
        productImageClicked() {
            window.open(this.productData.link);
            this.trackMetric('product_tile_image_click');
        },
        async moreLikeThisClicked() {
            this.sidebarImageUrl = this.productData.additionalImageLink[0];
            this.objectDetectionInput = null;
            this.moreLikeThisProductId = this.productData.id;
            await this.trackMetric('more_like_this');
            this.$store.dispatch('productFeedStore/runSearchQuery', { 'isMoreLikeThisQuery': true });
        },
        async trackMetric(eventType) {
            const currentTime = new Date().getTime();

            const params = {
                'tile_rank': this.tileRank,
                'time_since_open_ms': currentTime - this.$store.state.productFeedStore.openTime,
                'time_since_last_query_ms': currentTime - this.$store.state.productFeedStore.lastQueryStartTime,
                'ip_address': this.$store.state.productFeedStore.ipAddress,
                'protobuf_fields': this.productData,
                'query_id': this.$store.state.productFeedStore.lastQueryId,
                'parent_application': this.$store.state.productFeedStore.parentApplication,
                'parent_application_session_id': this.$store.state.productFeedStore.parentApplicationSessionId,
                'version': this.$store.state.productFeedStore.version,
            };

            const kinesisPayload = {
                'timestamp': currentTime / 1000.0,
                'application': this.$store.state.productFeedStore.application,
                'event': eventType,
                'params': params,
                'session_id': this.$store.state.productFeedStore.sessionId,
                'client_id': this.$store.state.productFeedStore.clientId,
            };
            await this.$store.dispatch('productFeedStore/recordAnalyticsEvent', kinesisPayload);
        },
    },
};
</script>

<style scoped lang="scss">
@import "../assets/colors.scss"; // copied from Admin4 for color consistency

.product-tile {
    border: 1px solid black;
    margin-bottom: 1.5em;

    &.is-one-quarter {
        width: 24%;
        margin-left: 1%;
    }
    #product-tile-buttons{
        display: flex;
        margin-top: 1%;
    }

    #more-like-this-tooltip{
        display: flex;
    }
    #more-like-this-btn-area{
        width: 25%;
    }

    #add-product-btn-area{
        width: 75%;
    }
    #add-product-button{
        background-color: $primary;
        color: white;
    }

    img {
        &:hover {
            cursor: pointer;
            opacity: 0.75;
        }
    }
}
</style>
